import { Injectable } from '@angular/core';
import {decrypt, encrypt} from "../../../../../common/general-functions";

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
    providedIn: 'root'
})

export class TokenStorageService {
    constructor() {}

    signOut(): void {
        window.localStorage.clear();
    }

    public saveToken(token: string): void {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, encrypt(token));
    }

    public getToken(): string | null {
        var token = window.localStorage.getItem(TOKEN_KEY);
        return token ? decrypt(token) : null;
    }

    public saveUser(user: any): void {
        window.localStorage.removeItem('general-chat-user');
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.setItem(USER_KEY, encrypt(JSON.stringify(user)));
    }

    public getUser(): any {
        const user = window.localStorage.getItem(USER_KEY);
        if (user) {
            return JSON.parse(decrypt(user));
        }
        return false;
    }

    public removeData(): void{
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(USER_KEY);
    }
}
