import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {catchError, map, Observable, of} from 'rxjs';
import { TokenStorageService } from "../services/token-storage.service";
import {Router} from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private tokenStorageService: TokenStorageService,
        private router: Router
    ) {}

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 403) {
            this.tokenStorageService.removeData();
            this.router.navigate([`/sign-in`]);
        }
        return of(err.message || err.error);
    }

    intercept(request: HttpRequest <unknown> , next: HttpHandler): Observable <HttpEvent<unknown>> {
        let token = this.tokenStorageService.getToken();
        if(token) {
            request = request.clone({
                headers: request.headers.set("access-token", token)
            })
        }

        return next.handle(request).pipe(
            catchError(x=> this.handleAuthError(x)),
            map((res: any) => {
                return res;
            }));
    }
}
