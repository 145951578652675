// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    APP_URL: "https://rmnevents.vitec-visual-dev.com",
    API_URL: "https://rmnevents.vitec-visual-dev.com:4202/api/",
    // API_URL: "http://localhost:4201/api/",
    ADMIN_URL: "https://rmnevents.vitec-visual-dev.com:4202/api/admin/",
    // ADMIN_URL: "http://localhost:4201/api/admin/",
    SOCKET_URL: "https://rmnevents.vitec-visual-dev.com:4202/",
    // SOCKET_URL: "http://localhost:4201/",
    RESOURCE_URL: "https://rmnevents.vitec-visual-dev.com:4202",
    GOOGLE_CONFIG:{
        CLIENT_ID: "479833908908-k1k4roqiip03g6f010727q6kq39jd5tc.apps.googleusercontent.com",
        SECRET: "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",
        GTAG: 'G-Z534W78NGD'
    },
    FACEBOOK_CONFIG:{
        CLIENT_ID: "650587119529440",
        SECRET: "c9da4b963181f679b4bd59d15b740795",
        PIXEL: "176077644133057"
    },
    STRIPE_PUBLISHABLE: "pk_test_51NVdbkHEyLP5wJOOPRYDeLILx75oH6jhdmK6nwROaNnsQWfKwPzHFVTjrCpzmip5ebK1znBmwHdcKLud8DC3FSyK00yQF1iWyy"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
