import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './interceptor/error-interceptor';
import {FormsModule} from "@angular/forms";
import {ModalModule} from "ngx-bootstrap/modal";
import {CarouselModule} from "ngx-bootstrap/carousel";
import {NgcCookieConsentConfig, NgcCookieConsentModule} from "ngx-cookieconsent";
import {environment} from "../../../../environments/environment";
import {NgxSpinnerModule} from "ngx-spinner";

const cookieConfig:NgcCookieConsentConfig = {
    position: "bottom",
    theme: "classic",
    palette: {
        popup: {
            "background": "#282828",
            "text": "#ffffff",
            "link": "#ffffff"
        },
        button: {
            "background": "#0a52be",
            "text": "#fff",
            "border": "1px solid #0a52be"
        }
    },
    type: "info",
    content: {
        "message": "This website uses cookies to ensure you get the best experience on our website.",
        "dismiss": "Got it!",
        "link": "Learn more",
        "href": '/privacy-policy',
        "policy": "Cookie Policy"
    }
};


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ModalModule.forRoot(),
        CarouselModule.forRoot(),
        ToastrModule.forRoot(),
        NgcCookieConsentModule.forRoot(cookieConfig),
        NgxSpinnerModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
