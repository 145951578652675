import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {environment} from "../../../../../environments/environment";

declare let fbq: Function;
declare let gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService implements OnDestroy {
    private _routerEventSubscription: Subscription;

    constructor(private router: Router) {
    }

    public ngOnDestroy(): void {
        if (this._routerEventSubscription) {
            this._routerEventSubscription.unsubscribe();
        }
    }

    public init(): void {
        if (environment.FACEBOOK_CONFIG.PIXEL) {
            const head = document.getElementsByTagName('head')[0];
            const script: HTMLScriptElement = document.createElement('script');
            script.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init','${environment.FACEBOOK_CONFIG.PIXEL}');`;
            head.appendChild(script);
        }
        if(environment.GOOGLE_CONFIG.GTAG){
            const head = document.getElementsByTagName('head')[0];
            const scriptJs: HTMLScriptElement = document.createElement('script');
            scriptJs.src = "https://www.googletagmanager.com/gtag/js?id="+ environment.GOOGLE_CONFIG.GTAG;
            scriptJs.async = true;
            head.appendChild(scriptJs);

            const script: HTMLScriptElement = document.createElement('script');
            script.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${environment.GOOGLE_CONFIG.GTAG}')`;
            head.appendChild(script);
        }

        this._subscribeRouterEvents();
    }

    public trackCustom(event: string, data: any = {}): void {
        fbq('trackCustom', event, data);
    }

    public track(event, data: any = {}): void{
        fbq('track', event, data);
    }

    public trackGtag(event, data: any = {}): void{
        gtag('event', event, data)
    }

    private _subscribeRouterEvents(): void {
        this._routerEventSubscription = this.router.events.subscribe((ev: NavigationEnd) => {
            if (ev instanceof NavigationEnd) {
                fbq('track', 'PageView');
                gtag('event', 'page_view', {url : ev.url})
            }
        });
    }

    public async trackAddToCart(products:any, module: string, eventTitle: string): Promise<void>{
        let analyticsData = {
            content_ids : products.map((p:any) => p.id),
            content_category : module,
            content_name : eventTitle,
            content_type: 'products',
            contents: products
        }

        this.track('AddToCart', analyticsData);

        let gtagData = {
            currency: 'USD',
            value: products.reduce((n,p) => n + (p.price * p.quantity), 0),
            items: products.map(p => {
                return {
                    item_id: p.id,
                    item_name: p.title,
                    item_list_name: eventTitle,
                    price: p.price,
                    quantity: p.quantity
                }
            })
        }
        this.trackGtag('add_to_cart', gtagData);
    }

    public async trackAthleteSelection(selectedAthletes, userAthletes, eventTitle): Promise<void>{
        let registrationData = selectedAthletes.map((a:any) =>{
            let athlete = userAthletes.find(ath => a.athlete_id == ath.id);
            return {
                athlete: athlete.first_name + ' '+ athlete.last_name,
                registrations: a.divisions.map((a:any) => a.weight_classes).flat().length
            }
        });

        this.trackCustom("AthleteSelection", {event: eventTitle, registrations: registrationData});

        let gtagData = {
            item_list_name: eventTitle,
            items: selectedAthletes.map(a => {
                let athlete = userAthletes.find(ath => a.athlete_id == ath.id);
                return {
                    item_id: athlete.id,
                    item_name : athlete.first_name + ' '+ athlete.last_name,
                    quantity: a.divisions.map((a:any) => a.weight_classes).flat().length
                }
            })
        }
        this.trackGtag('select_item', gtagData);
    }

    public async trackInitiateCheckout(module, items: any[] = []){
        let analyticsData = {
            content_category : module,
        }
        this.track('InitiateCheckout', analyticsData);

        let gtagData = {
            currency: 'USD',
            value: items.reduce((n,p) => n + (p.price * p.qty), 0),
            items: items.map(i => {
                return {
                    item_id: i.id ?? null,
                    item_name: i.title,
                    quantity: i.qty,
                    price: i.price,
                    item_list_name: module
                }
            })
        }
        this.trackGtag('begin_checkout', gtagData);
    }

    public async trackPurchase(module, event, data){
        let analyticsData = {
            content_category: module,
            content_name : event,
            currency: 'USD',
            value: data.amount
        }
        this.track('Purchase', analyticsData);

        let gtagData = {
            currency: 'USD',
            transaction_id: data.transactionId ?? 'transaction-'+new Date().getTime(),
            value: data.amount,
            coupon: data.coupon,
            items: data.products.map(i => {
                return {
                    item_id: i.id ?? null,
                    item_name: i.title,
                    quantity: i.qty,
                    price: i.price,
                    item_list_name: module
                }
            })
        }
        this.trackGtag('purchase', gtagData);
    }
}
