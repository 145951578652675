import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CMSApi } from "../../../../../const/web.url";
import { map } from "rxjs/operators";
import {of, Subject} from "rxjs";
import {CacheService} from "./cache.service";

@Injectable({
    providedIn: 'root'
})
export class CmsService {
    cmsPages: Subject<any> = new Subject<any>();

    constructor(
        private http: HttpClient,
        private cacheService: CacheService
    ) { }

    cmsList(body?: any, fresh: boolean = false) {
        const cms_cache = "cms_data";
        if(!fresh){
            let response = this.cacheService.get(cms_cache);
            if(response){
                return of(response);
            }
        }

        return this.http.post<any>(CMSApi.cmsList, body).pipe(map((response) => {
            if(response.status){
                let expiry = new Date();
                expiry.setDate(expiry.getDate() + 7);
                this.cacheService.set(cms_cache, response, expiry);
            }
            return response;
        }));
    }

    cmsDetails(key:string){
        return this.http.get<any>(CMSApi.getCmsDetail + '/' + key).pipe(map((response) => {

            return response;
        }));
    }

    faqList(){
        return this.http.get<any>(CMSApi.faqList).pipe(map((response) => {
            return response;
        }));
    }

    fetchWebPagesList(){
        return this.http.post<any>(CMSApi.pagesList, {}).pipe(map((response) => {
            if(response.status){
                this.cmsPages.next(response.responseData.data);
            }
            return response;
        }));
    }

}
